<template>
  <div class="mt-4 md:mt-0 font-proximaMedium">
    <h5 v-if="title" class="mb-4 text-base">{{ title }}:</h5>
    <label class="w-max container font-proximaLight capitalize"
      >{{ opt1 }}
      <input
        type="radio"
        id="1"
        :value="1"
        v-model="picked"
        @change="$parent.$emit('blurred', 1)"
        :disabled="editable"
      />
      <!-- :disabled="!editable" -->
      <span class="checkmark"></span>
    </label>
    <label class="w-max container font-proximaLight capitalize"
      >{{ opt2 }}
      <input
        type="radio"
        id="2"
        :value="2"
        v-model="picked"
        @change="$parent.$emit('blurred', 2)"
        :disabled="editable"
      />
      <!-- :disabled="!editable" -->
      <span class="checkmark"></span>
    </label>
    <label class="w-max container font-proximaLight capitalize"
      >{{ opt3 }}
      <input
        type="radio"
        id="3"
        :value="3"
        v-model="picked"
        @change="$parent.$emit('blurred', 3)"
        :disabled="editable"
      />
      <!-- :disabled="!editable" -->
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Title",
    },
    opt1: {
      type: String,
      default: "Opt 1",
    },
    opt2: {
      type: String,
      default: "Opt 2",
    },
    opt3: {
      type: String,
      default: "Opt 3",
    },
    val: {
      type: Number,
      default: 1,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      picked: Number(this.val),
    };
  },
  watch: {
    picked: function () {
      this.$emit("input", this.picked);
    },
  },
};
</script>

<style scoped>
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
</style>
