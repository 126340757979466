<template>
  <div class="flex justify-center items-center flex-col cursor-pointer">
    <div>
      <img
        v-if="url"
        :src="url"
        class="rounded-full object-cover w-24 h-24 cursor-pointer"
      />
      <img
        v-else
        :src="image"
        class="rounded-full object-cover w-24 h-24 cursor-pointer"
      />
    </div>
    <div class="my-2 flex items-center justify-center cursor-pointer">
      <input
        type="file"
        @change="onFileChange"
        id="upload-photo"
        class="-z-1 opacity-0 absolute cursor-pointer"
      />

      <label
        for="upload-photo"
        class="mt-2 font-proximaMedium text-mediumGrey text-xs text-center cursor-pointer"
        >{{ title }}</label
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Select Options",
    },
    image: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      url: require(`@/assets/images/profilepic-icon.png`),
    };
  },
  methods: {
    onFileChange(e) {
      let file = e.target.files[0];
      console.log("///////", e.target.files[0]);
      this.url = URL.createObjectURL(file);
      this.$emit("blurred", file);
    },
  },
};
</script>
