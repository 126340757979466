<template>
  <div class="flex justify-center items-center flex-col">
    <div
      v-if="errors.length > 0"
      class="my-8 flex flex-col justify-center items-center text-red"
    >
      <h5 v-if="errors.length > 0">{{ $t("global_error") }}</h5>
      <ul
        v-for="(error, i) in this.errors"
        :key="i"
        class="mt-2 font-proximaLight text-sm text-left list-disc"
      >
        <li>{{ error }}</li>
      </ul>
    </div>
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-start items-start"
    >
      <Input
        label="Species"
        v-model="species"
        classAdd="w-6/12 mr-2 flex flex-col justify-start items-start"
        :editable="editable"
      />
    </div>
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-start items-start"
    >
      <Input
        :label="$t('global_name')"
        :editable="editable"
        v-model="petData.callName"
        classAdd="w-6/12 mr-2 flex flex-col justify-start items-start"
        :error="errors.callName"
      />
    </div>
    <!-- pet gender & breeding status -->
    <div class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row items-center">
      <div class="w-full flex items-center justify-center">
        <InputRadioDisabledVue
          class="mt-4"
          :title="$t('global_sex')"
          :opt1="$t('global_female')"
          :opt2="$t('global_male')"
          v-if="!editable"
          :val="petData.sex == 0 ? 2 : 1"
        />
        <InputRadio
          class="mt-4"
          :title="$t('global_sex')"
          :opt1="$t('global_female')"
          :opt2="$t('global_male')"
          @input="inputSexType"
          v-else
          :val="petData.sex == 0 ? 2 : 1"
        />
      </div>
      <div class="w-full flex items-center justify-center">
        <InputRadioDisabledVue
          class="mt-4"
          :title="$t('pet_record_breeding_status')"
          :opt1="$t('global_sterilized')"
          :opt2="$t('global_intact')"
          v-if="!editable"
          :val="petData.breedingStatus == 0 ? 2 : 1"
        />
        <InputRadio
          class="mt-4"
          :title="$t('pet_record_breeding_status')"
          :opt1="$t('global_sterilized')"
          :opt2="$t('global_intact')"
          v-else
          :val="petData.breedingStatus == 0 ? 2 : 1"
          @input="inputBreedingStatus"
        />
      </div>
    </div>
    <!-- pet gender & breeding status -->
    <!-- DOB -->
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between items-center"
    >
      <div class="w-full flex items-center justify-center">
        <InputRadioDisabledVue
          class="mt-4"
          :title="$t('pet_record_breeding_status')"
          :opt1="$t('global_sterilized')"
          :opt2="$t('global_intact')"
          v-if="!editable"
        />
        <InputRadio
          class="mt-4"
          title="Do you know your pet’s date of birth?* "
          opt1="Yes"
          opt2="No"
          @input="questionDOB = !questionDOB"
          v-else
        />
      </div>

      <div
        class="w-full mr-2 flex flex-col justify-start items-start"
        v-if="!editable"
      >
        <Input
          :label="$t('global_date_of_birth')"
          v-model="time2"
          :editable="editable"
          @update="changeShowTitle"
          placeholder="Multi BISS, AKC Champion, Canadian GrCH"
          classAdd="w-full mr-2 flex flex-col justify-start items-start relative"
          :error="errors.showTitle"
        />
      </div>
      <div class="w-full mr-2 flex flex-col justify-start items-start" v-else>
        <label class="text-sm font-proximaMedium" for="dateOfBirth">{{
          $t("global_date_of_birth") + "**"
        }}</label>
        <date-picker
          :disabled="questionDOB"
          type="date"
          format="DD/MM/YYYY"
          v-model="time2"
          @change="onChange()"
          placeholder="Select date"
          class="w-full rounded-sm my-1 text-sm"
          :class="{ 'border border-red outline-red': error }"
        ></date-picker>
      </div>
    </div>
    <!-- DOB end -->
    <!-- ID band number -->
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between items-center"
    >
      <div class="w-full flex items-center justify-center">
        <InputRadioDisabledVue
          class="mt-4"
          title="Does your pet have permanent ID?*"
          :opt1="$t('global_yes')"
          :opt2="$t('global_no')"
          v-if="!editable"
        />
        <InputRadio
          class="mt-4"
          title="Does your pet have permanent ID?*"
          :opt1="$t('global_yes')"
          :opt2="$t('global_no')"
          @input="questionIdBand = !questionIdBand"
          v-else
        />
      </div>

      <Input
        :label="$t('pet_record_microchip_number')"
        :editable="editable && !questionIdBand"
        v-model="petData.microchipNo"
        classAdd="w-full mr-2 flex flex-col justify-start items-start relative cursor-pointer"
        :error="errors.microchipNo"
      />
    </div>
    <!-- ID band number end -->
    <!-- country of origin -->
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between items-center"
    >
      <div class="w-full flex items-center justify-center">
        <InputRadioDisabledVue
          class="mt-4"
          title="Is your pet imported?*"
          :opt1="$t('global_yes')"
          :opt2="$t('global_no')"
          v-if="!editable"
        />
        <InputRadio
          class="mt-4"
          title="Is your pet imported?*"
          opt1="Yes"
          opt2="No"
          @input="questionCountryOrigin = !questionCountryOrigin"
          v-else
        />
      </div>

      <div class="w-full mr-2 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="origin">{{
          $t("global_country_of_origin") + "**"
        }}</label>
        <select
          v-if="!editable"
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          :class="{
            'bg-greyishWhite': questionCountryOrigin,
          }"
          v-model="petData.CountryofOrigin"
          disabled
        >
          <option value="" disabled selected>
            {{ $t("global_select_country") }}
          </option>
          <option v-for="item in countriesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
        <select
          v-else
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          :class="{
            'bg-greyishWhite': questionCountryOrigin,
          }"
          v-model="petData.CountryofOrigin"
          :disabled="questionCountryOrigin"
        >
          <option value="" disabled selected>
            {{ $t("global_select_country") }}
          </option>
          <option v-for="item in countriesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    <!-- country of origin end -->
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between"
    >
      <Input
        :label="$t('pet_record_height')"
        :editable="editable"
        v-model="petData.height"
        classAdd="w-full flex flex-col justify-start items-start relative"
        :error="errors.height"
      />
      <Input
        :label="$t('pet_record_weight')"
        :editable="editable"
        v-model="petData.weight"
        classAdd="w-full ml-4 mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start relative"
        :error="errors.weight"
      />
    </div>
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between"
    >
      <Input
        label="Working Titles (optional)"
        v-model="petData.showTitle"
        :editable="editable"
        @update="changeShowTitle"
        placeholder="Multi BISS, AKC Champion, Canadian GrCH"
        classAdd="w-full flex flex-col justify-start items-start relative"
        :error="errors.showTitle"
      />
      <Input
        :label="$t('pet_record_show_titles')"
        v-model="petData.showTitle"
        :editable="editable"
        @update="changeShowTitle"
        placeholder="Multi BISS, AKC Champion, Canadian GrCH"
        classAdd="w-full ml-2 flex flex-col justify-start items-start relative"
        :error="errors.showTitle"
      />
    </div>
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between"
    >
      <Input
        label="Primary ID Type (optional)"
        :editable="editable"
        v-model="petData.optionalIDType"
        classAdd="w-full flex flex-col justify-start items-start relative"
        :error="errors.optionalIDType"
      />
      <Input
        label="Primary ID (optional)"
        :editable="editable"
        v-model="petData.optionalID"
        classAdd=" w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start relative"
        :error="errors.optionalID"
      />
    </div>
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between"
    >
      <Input
        :label="$t('pet_record_secondary_id_type')"
        :editable="editable"
        v-model="petData.optionalIDType"
        classAdd="w-full flex flex-col justify-start items-start relative"
        :error="errors.optionalIDType"
      />
      <Input
        :label="$t('pet_record_secondary_id')"
        :editable="editable"
        v-model="petData.optionalID"
        classAdd=" w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start relative"
        :error="errors.optionalID"
      />
    </div>
    <div
      class="my-8 w-12/12 md:w-6/12 lg:w-8/12 flex flex-wrap justify-start md:justify-arround items-start"
      v-if="!editable"
    >
      <div v-for="g in this.petData.petGallery" :key="g.id">
        <displayImagePets
          class="mx-1 mt-4 rounded-md"
          :imageData="g.petImage"
        />
        <div class="filler mx-3"></div>
      </div>
      <!--  <div v-for="g in gall.slice(this.petData.petGallery.length)" :key="g">
        <displayImagePets class="mx-1 mt-4 rounded-md" :imageData="null" />
        <div class="filler mx-3"></div>
      </div> -->
    </div>
    <div
      class="my-8 w-12/12 md:w-6/12 lg:w-8/12 flex flex-wrap justify-center md:justify-between items-center"
      v-else
    >
      <div v-for="g in this.petData.petGallery" :key="g.id">
        <displayImagePets
          class="mx-1 mt-4 rounded-md"
          :imageData="g.petImage"
        />
        <div class="filler mx-3"></div>
      </div>
      <div v-for="g in gall.slice(this.petData.petGallery.length)" :key="g">
        <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />

        <div class="filler mx-3"></div>
      </div>
    </div>
    <!-- unverifiedHealthStatus -->
    <NewpetDiagnoses
      :unverifiedHealthStatus="petData.unverifiedHealthStatus"
      :editable="editable"
      @changeDignostic="changeDignostic"
      @deleteDiag="deleteDiag"
    />
    <div
      class="mt-8 w-11/12 md:w-6/12 lg:w-4/12 text-left font-proximaMedium text-sm"
    >
      <h5 v-if="editable">{{ $t("pet_record_vet_email_address") }}</h5>
      <input
        v-if="editable"
        class="w-full px-4 py-2 my-2 rounded-sm font-proximaLight"
        type="email"
        :placeholder="$t('pet_record_enter_email_address_vet')"
      />
      <p class="font-proximaLight">
        <span class="text-red">{{ $t("global_note") }}</span>
        {{ $t("pet_record_text") }}
      </p>
    </div>
    <div
      v-if="petData.doc"
      class="w-full md:w-4/12 mt-4 flex flex-col md:flex-row items-center md:items-start justify-between"
    >
      <displayImagePets
        v-if="!editable"
        class="mx-1 mt-4 rounded-md"
        :imageData="petData.petIdentificationImage"
      />
      <InputImagePets
        v-else
        class="mx-1 mt-4"
        :imageData="petData.petIdentificationImage"
        @input="pushToDoc"
      />
      <InputRadio3v
        class="mt-4"
        title="Document Type"
        :opt1="$t('pet_record_official_pedegree')"
        :opt2="$t('pet_record_EU_pet_passport')"
        :opt3="$t('pet_record_vet_hospital_bill')"
        :val="petData.petIdentificationImageType"
        @input="inputVal"
      />
    </div>
    <div
      v-else
      class="w-full md:w-4/12 mt-4 flex flex-col md:flex-row items-center md:items-start justify-between"
    >
      <InputImagePets class="mx-1 mt-4" @input="pushToDoc" />
      <InputRadio3v
        class="mt-4"
        title="Document Type"
        :opt1="$t('pet_record_official_pedegree')"
        :opt2="$t('pet_record_EU_pet_passport')"
        :opt3="$t('pet_record_vet_hospital_bill')"
        @input="inputVal"
      />
    </div>
    <div
      class="mt-0 w-full font-proximaMedium flex justify-center items-center"
    >
      <div
        class="w-11/12 md:w-6/12 lg:w-4/12 md:mt-4 flex flex-row justify-center items-center"
        v-if="!editable"
      >
        <SubmitButton
          class="mr-1"
          paddingX="px-8"
          width="w-full md:w-10/12"
          :title="$t('account_edit_button')"
          background="bg-primary"
          @click.native="editAccount"
        />
        <BorderedButton
          class="ml-1"
          paddingX="px-8"
          width="w-full md:w-10/12"
          :title="$t('global_delete')"
          borderColor="border-red"
          textCl="text-red"
          @click.native="openModal"
        />
      </div>
      <div
        class="w-11/12 md:w-6/12 lg:w-4/12 md:mt-4 flex flex-row justify-center items-center"
        v-else
      >
        <BorderedButton
          class="mr-1"
          paddingX="px-8"
          width="w-full md:w-10/12"
          :title="$t('global_cancel')"
          borderColor="border-mediumGrey"
          textCl="text-darkGrey"
          @click.native="editAccount"
        />
        <SubmitButton
          class="ml-1"
          paddingX="px-8"
          width="w-full md:w-10/12"
          :title="$t('account_save_button')"
          background="bg-primary"
          @click.native="editAccount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputSelect from "../../forms/InputSelect.vue";
import InputRadio3v from "../../forms/InputRadio3v.vue";
import InputRadio from "../../forms/InputRadio.vue";
import FormError from "../../warning/FormError.vue";
import NewpetDiagnoses from "../NewpetDiagnoses.vue";
import InputImagePets from "../../forms/InputImagePets.vue";
import displayImagePets from "../../forms/displayImagePets.vue";
import InputRadioEach from "../../forms/InputRadioEach.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import DatePicker from "vue2-datepicker";
import Input from "../../forms/Input_.vue";
import "vue2-datepicker/index.css";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import InputRadioDisabledVue from "../../forms/InputRadioDisabled.vue";

import { getCountries } from "../../../services/countriesServices";

export default {
  components: {
    Input,
    SubmitButton,
    BorderedButton,
    InputSelect,
    InputRadio,
    NewpetDiagnoses,
    InputImagePets,
    displayImagePets,
    InputRadioEach,
    FormError,
    DatePicker,
    InputRadio3v,
    InputRadioDisabledVue,
  },
  data() {
    return {
      petData: {},
      name: "",
      dob: "",
      species: "",
      origin: "",
      secondId: "",
      secondId2: "",
      time2: null,
      petRecord: "",
      editable: false,
      questionDOB: false,
      questionIdBand: false,
      questionCountryOrigin: false,
      questionPureBred: false,
      questionPedPapers: false,
      countriesList: [],
      unverifiedHealthStatus: [],
      petGallery: [],
      picture: null,
      error: false,
      errors: {},
      gall: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    };
  },
  computed: {
    ...mapGetters({
      getError: "petRecord/getError",
    }),
  },
  async created() {
    // Retrieve the pet object from storage
    var retrievedObject = localStorage.getItem("pet");

    this.petData = JSON.parse(retrievedObject);
    this.petData.petIdentificationImage = this.petData.doc
      ? this.petData.doc.document
      : null;
    this.petData.petIdentificationImageType = this.petData.doc
      ? this.petData.doc.type
      : 1;

    const a = await getCountries();
    this.countriesList = a.data;
  },
  methods: {
    ...mapActions({
      updatePetRecord: "petRecord/updatePetRecord",
    }),
    inputSelect(val) {
      this.petRecord = val;
    },
    pushToDoc(val) {
      this.petData.petIdentificationImage = val;
    },
    async inputSelectCountry() {
      const a = await getStates(this.petData.CountryofOrigin);
      this.statesList = a.data;
    },
    changeDignostic(val) {
      //findIndex((obj) => obj.name === line)
      val.split(",").map((line) => {
        if (
          val[val.length - 1] == "," &&
          this.unverifiedHealthStatus.findIndex((obj) => obj === line) == -1 &&
          line != ""
        ) {
          this.unverifiedHealthStatus.push(line);
        }
      });
      console.log("unverifiedHealthStatus --- ", this.unverifiedHealthStatus);
    },
    inputVal(i) {
      // console.log(i);
      this.petData.petIdentificationImageType = i;
    },

    deleteDiag(val) {
      const diags = this.unverifiedHealthStatus.filter((c) => c.id !== val);
      this.unverifiedHealthStatus = diags;
      console.log(val);
    },
    pushToGallery(val) {
      this.petGallery.push({ petImage: val, primary_picture: 0 });
    },
    validate() {
      const errors = [];

      if (this.petData.callName.trim() === "") errors.push("Name is required");
      if (this.petData.dateOfBirth === null) {
        errors.push(this.$t("pet_record_error_1"));
        errors.push(this.$t("pet_record_error_2"));
      }
      if (this.petData.petIdentificationImage == null)
        errors.push("A pet’s identity must be added");
      return Object.keys(errors).length === 0 ? null : errors;
    },
    openModal() {
      this.$store.commit("showDeleteModals", true);
    },
    async editAccount() {
      if (this.editable) {
        this.editable = !this.editable;
        this.$emit("editable", this.editable);
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.titlemenu3 = this.$t("account_dropdown_menu_edit_pet_records");
        if (this.petData.dateOfBirth === null) {
          this.errorData = true;
        }
        const errors = this.validate();
        this.errors = errors || [];
        if (errors) {
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }

        if (this.file) {
          const index = this.petData.petGallery.findIndex(
            (p) => p.primary_picture === 1
          );

          if (index !== -1) {
            this.petData.petGallery.splice(index, 1, {
              petImage: this.file,
              primary_picture: 1,
            });
            console.log(this.petData.petGallery);
          } else {
            this.petData.petGallery.push({
              petImage: this.file,
              primary_picture: 1,
            });
            console.log(this.petData.petGallery);
          }

          console.log(this.petGallery);
        }

        this.petData.dateOfBirth = moment(String(this.time2)).format(
          "DD/MM/YYYY"
        );

        const data = {
          petData: this.petData,
          userEmailAddress: localStorage.getItem("email"),
          vetEmailAddress: this.vetEmailAddress,
          unverifiedHealthStatus: this.unverifiedHealthStatus,
          petProfileImg: this.file ? this.file : null,
          petGallery: this.petData.petGallery,
        };
        console.log(data);

        this.updatePetRecord(data);
      } else {
        this.editable = !this.editable;
        this.$emit("editable", this.editable);
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.titlemenu3 = "";
      }
    },
  },
};
</script>

<style>
.filler {
  width: 180px;
  height: 0px;
}
.mx-datepicker {
  width: 100%;
}

.mx-datepicker > div > input {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.mx-input {
  border: none;
  height: auto;
}
</style>
