<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.125 13.3438V6.4375H3.1875V13.3438C3.1875 13.4846 3.24347 13.6198 3.3431 13.7194C3.44273 13.819 3.57785 13.875 3.71875 13.875H13.2812C13.4221 13.875 13.5573 13.819 13.6569 13.7194C13.7565 13.6198 13.8125 13.4846 13.8125 13.3438V6.4375H14.875V13.3438C14.875 13.7664 14.7071 14.1718 14.4082 14.4707C14.1093 14.7696 13.7039 14.9375 13.2812 14.9375H3.71875C3.29606 14.9375 2.89068 14.7696 2.5918 14.4707C2.29291 14.1718 2.125 13.7664 2.125 13.3438ZM13.8125 1.65625V5.375L11.6875 3.25V1.65625C11.6875 1.51535 11.7435 1.38023 11.8431 1.2806C11.9427 1.18097 12.0779 1.125 12.2188 1.125H13.2812C13.4221 1.125 13.5573 1.18097 13.6569 1.2806C13.7565 1.38023 13.8125 1.51535 13.8125 1.65625Z"
      :fill="iconColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.74949 0.592336C7.94873 0.393148 8.21894 0.28125 8.50067 0.28125C8.78241 0.28125 9.05261 0.393148 9.25186 0.592336L16.3143 7.65371C16.4141 7.75347 16.4701 7.88876 16.4701 8.02984C16.4701 8.17091 16.4141 8.30621 16.3143 8.40596C16.2145 8.50572 16.0792 8.56176 15.9382 8.56176C15.7971 8.56176 15.6618 8.50572 15.562 8.40596L8.50067 1.34352L1.4393 8.40596C1.33954 8.50572 1.20425 8.56176 1.06317 8.56176C0.922097 8.56176 0.786801 8.50572 0.687046 8.40596C0.587292 8.30621 0.53125 8.17091 0.53125 8.02984C0.53125 7.88876 0.587292 7.75347 0.687046 7.65371L7.74949 0.592336Z"
      :fill="iconColor"
    />
    <path
      d="M11.431 10.5717C11.2895 10.1564 10.9593 9.94579 10.6407 9.74209C10.3636 9.56526 10.1018 9.39751 9.93046 9.0973C9.45849 8.264 9.19928 7.76172 8.42666 7.76172C7.65404 7.76172 7.39335 8.26443 6.92032 9.09686C6.74868 9.39827 6.48576 9.56679 6.20827 9.74362C5.88945 9.94732 5.55999 10.1574 5.41801 10.5718C5.36273 10.7212 5.33491 10.88 5.33597 11.0399C5.33597 11.7141 5.8581 12.2627 6.43655 12.2627C7.01501 12.2627 7.77668 11.9623 8.42794 11.9623C9.07919 11.9623 9.83077 12.2624 10.4159 12.2624C11.0011 12.2624 11.5148 11.7138 11.5148 11.0396C11.5152 10.8796 11.4868 10.7209 11.4311 10.5716L11.431 10.5717Z"
      :fill="iconColor"
    />
    <path
      d="M5.46361 9.35508C5.9614 9.35508 6.36472 8.82166 6.36472 8.16387C6.36472 7.50608 5.9614 6.97266 5.46361 6.97266C4.96581 6.97266 4.5625 7.50586 4.5625 8.16387C4.5625 8.82188 4.96581 9.35508 5.46361 9.35508Z"
      :fill="iconColor"
    />
    <path
      d="M7.26439 7.76524C7.76218 7.76524 8.1655 7.23182 8.1655 6.57403C8.1655 5.91624 7.76165 5.38281 7.26439 5.38281C6.76713 5.38281 6.36328 5.9158 6.36328 6.57381C6.36328 7.23182 6.76713 7.76524 7.26439 7.76524Z"
      :fill="iconColor"
    />
    <path
      d="M9.5847 7.76524C10.0825 7.76524 10.4858 7.23182 10.4858 6.57403C10.4858 5.91624 10.082 5.38281 9.5847 5.38281C9.08744 5.38281 8.68359 5.91624 8.68359 6.57403C8.68359 7.23182 9.08702 7.76524 9.5847 7.76524Z"
      :fill="iconColor"
    />
    <path
      d="M11.3855 9.35508C11.8833 9.35508 12.2866 8.82166 12.2866 8.16387C12.2866 7.50608 11.8827 6.97266 11.3855 6.97266C10.8882 6.97266 10.4844 7.50608 10.4844 8.16387C10.4844 8.82166 10.8877 9.35508 11.3855 9.35508Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 33,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "#525252",
    },
  },
};
</script>