<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_32456_2779)">
      <path
        d="M17.914 1.6228C17.9066 1.48609 17.85 1.35667 17.7546 1.25844C17.6593 1.16021 17.5316 1.0998 17.3952 1.08836L10.0668 0.362249C9.98331 0.35379 9.89901 0.364061 9.82001 0.392312C9.74101 0.420564 9.6693 0.466085 9.61012 0.52556L0.971791 9.16629C-0.32393 10.4623 -0.32393 11.2528 0.971791 12.5485L6.45345 18.0319C7.03427 18.6131 7.54394 18.9993 8.14432 18.9993C8.7447 18.9993 9.25437 18.612 9.83405 18.0316L18.4738 9.39028C18.5331 9.33096 18.5785 9.25923 18.6067 9.18024C18.635 9.10126 18.6453 9.01699 18.6371 8.93352L17.914 1.6228ZM9.0327 17.2298C8.76029 17.5022 8.37166 17.8649 8.14432 17.8649C7.91698 17.8649 7.52778 17.5022 7.25509 17.2298L1.77343 11.7464C0.926153 10.8992 0.926153 10.8156 1.77343 9.96839L10.223 1.51705L16.8277 2.17086L17.482 8.77701L9.0327 17.2298Z"
        :fill="iconColor"
      />
      <path
        d="M5.93247 7.26969L3.35577 9.84666C2.7038 10.4974 2.52664 10.9385 3.27073 11.6836C3.32404 11.7369 3.39631 11.7668 3.47167 11.7668C3.54702 11.7668 3.61928 11.7368 3.67255 11.6835C3.72581 11.6302 3.75572 11.5579 3.75569 11.4825C3.75567 11.4071 3.72571 11.3349 3.6724 11.2816C3.22821 10.8373 3.2336 10.7712 3.75744 10.2464L6.33414 7.66946C6.36177 7.6434 6.38388 7.61204 6.39916 7.57726C6.41444 7.54248 6.42257 7.50499 6.42308 7.467C6.42359 7.42902 6.41646 7.39132 6.40211 7.35614C6.38777 7.32096 6.36651 7.28903 6.33958 7.26223C6.31266 7.23544 6.28063 7.21433 6.24539 7.20016C6.21015 7.18598 6.17242 7.17904 6.13445 7.17974C6.09648 7.18043 6.05903 7.18875 6.02433 7.20421C5.98964 7.21966 5.9584 7.24193 5.93247 7.26969Z"
        :fill="iconColor"
      />
      <path
        d="M6.73857 6.45879L6.50811 6.68958C6.48108 6.71577 6.45953 6.74709 6.44473 6.7817C6.42992 6.81631 6.42216 6.85352 6.42188 6.89117C6.42161 6.92881 6.42884 6.96614 6.44314 7.00096C6.45744 7.03578 6.47853 7.06741 6.50518 7.09399C6.53184 7.12057 6.56351 7.14158 6.59837 7.15579C6.63322 7.16999 6.67056 7.17711 6.7082 7.17673C6.74583 7.17636 6.78302 7.16848 6.81758 7.15358C6.85214 7.13867 6.88339 7.11703 6.9095 7.08991L7.13996 6.85912C7.19152 6.80558 7.22 6.73392 7.21925 6.65958C7.2185 6.58523 7.18859 6.51416 7.13595 6.46167C7.08332 6.40917 7.01218 6.37945 6.93785 6.37891C6.86352 6.37837 6.79196 6.40706 6.73857 6.45879Z"
        :fill="iconColor"
      />
      <path
        d="M13.4537 3.12109C12.9889 3.12154 12.5347 3.25981 12.1485 3.51841C11.7622 3.77701 11.4613 4.14435 11.2837 4.57397C11.1062 5.0036 11.06 5.47624 11.1509 5.93214C11.2419 6.38804 11.4659 6.80673 11.7947 7.1353C12.1235 7.46386 12.5424 7.68755 12.9982 7.77808C13.4541 7.86861 13.9266 7.82192 14.356 7.64391C14.7853 7.4659 15.1523 7.16457 15.4105 6.778C15.6687 6.39143 15.8065 5.93698 15.8065 5.47209C15.8059 4.84836 15.5577 4.25038 15.1166 3.80955C14.6754 3.36871 14.0773 3.12109 13.4537 3.12109ZM13.4537 6.68898C13.2132 6.68854 12.9782 6.61679 12.7784 6.4828C12.5786 6.34881 12.423 6.1586 12.3313 5.93621C12.2396 5.71381 12.2158 5.4692 12.263 5.2333C12.3102 4.99739 12.4262 4.78078 12.5965 4.61082C12.7667 4.44086 12.9835 4.32519 13.2194 4.27842C13.4554 4.23165 13.6999 4.25588 13.9221 4.34805C14.1443 4.44022 14.3341 4.59619 14.4677 4.79626C14.6013 4.99633 14.6726 5.23151 14.6726 5.47209C14.672 5.79504 14.5433 6.10456 14.3148 6.3327C14.0863 6.56085 13.7766 6.68898 13.4537 6.68898Z"
        :fill="iconColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_32456_2779">
        <path
          d="M0 5.1265C0 2.49369 2.13431 0.359375 4.76712 0.359375H13.8727C16.5055 0.359375 18.6398 2.49369 18.6398 5.1265V14.2321C18.6398 16.8649 16.5055 18.9992 13.8727 18.9992H4.76713C2.13432 18.9992 0 16.8649 0 14.2321V5.1265Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 33,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "#525252",
    },
  },
};
</script>