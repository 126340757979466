<template>
  <div class="mt-4 w-11/12 md:w-8/12 text-left font-proximaMedium text-sm">
    <h5>Notes:</h5>
    <!-- <textarea
      @input="changeDignostic"
      placeholder="you can enter extra information about your pet here, which will be private (viewable only by primary owner and co-owners)"
      class="mt-2 w-full h-20 p-2 text-sm rounded-sm font-proximaLight"
      v-if="editable"
    ></textarea> -->
    <div class="w-full" v-if="editable">
      <vue-tags-input
        v-model="tag"
        :tags="tags"
        :add-on-key="[',']"
        @tags-changed="(newTags) => changeDignostic(newTags)"
      />
    </div>
    <div v-else class="mt-4 flex justify-start">
      <ul class="w-11/12 md:w-6/12 flex justify-start flex-wrap">
        <li
          v-for="data in unverifiedHealthStatus"
          :key="data.id"
          class="w-max mr-2 mt-2 text-white bg-darkGrey rounded-md px-3 py-2 relative"
        >
          {{ data.healthDescription }}
          <div
            class="bg-white rounded-full absolute -right-1 -top-1 cursor-pointer"
          ></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import TrashIcon from "@/components/icons/TrashIcon.vue";
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  props: {
    unverifiedHealthStatus: [],
    editable: false,
  },
  data() {
    return {
      tag: "",
      tags: [],
    };
  },
  components: {
    TrashIcon,
    VueTagsInput,
  },
  data() {
    return {
      // { id: "1", name: "rabbit-directed aggression" },
    };
  },
  computed: {
    propTags() {
      //this.keyword?this.keyword.map(a=>this.tags.push(a)):"";

      return this.keyword;
    },
  },
  methods: {
    changeDignostic(newTags) {
      this.tags = newTags;
      console.log(this.tags);
      this.$emit("changeDignostic", this.tags);
    },
    deleteDiag(val) {
      this.$emit("deleteDiag", val);
    },
  },
};
</script>

<style>
.vue-tags-input {
  max-width: 100% !important;
}

.vue-tags-input .ti-input {
  border: 2px solid #fff;
  padding: 6px 4px;
  border-radius: 0.375rem;
}

.ti-tag {
  padding: 8px !important;
  border-radius: 0.375rem !important;
  background-color: #0ac0cb !important;
}
</style>
