
import http from "./httpService";
import { apiUrl } from "../config.json";


export function getCountries() {
  return http.get(apiUrl + "common/countries/" );   
  //return http.get("http://localhost:8000/api/v1/" +"common/countries/" );   
}

