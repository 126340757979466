<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_32456_2812)">
      <path
        d="M8.54327 17.0869C6.85358 17.0869 5.20182 16.5858 3.79689 15.647C2.39195 14.7083 1.29694 13.374 0.650322 11.8129C0.00370171 10.2517 -0.165484 8.53395 0.16416 6.87669C0.493804 5.21943 1.30747 3.69713 2.50227 2.50231C3.69707 1.3075 5.21933 0.493813 6.87657 0.164163C8.5338 -0.165487 10.2516 0.00370173 11.8126 0.650333C13.3737 1.29696 14.708 2.392 15.6467 3.79696C16.5855 5.20191 17.0865 6.8537 17.0865 8.54343C17.0839 10.8085 16.183 12.98 14.5814 14.5816C12.9798 16.1832 10.8083 17.0842 8.54327 17.0869ZM8.54327 1.55349C7.16083 1.55349 5.80943 1.96344 4.65996 2.7315C3.5105 3.49955 2.61459 4.59122 2.08555 5.86846C1.5565 7.14569 1.41807 8.55112 1.68776 9.90703C1.95745 11.2629 2.62315 12.5084 3.60067 13.486C4.5782 14.4636 5.82365 15.1293 7.17953 15.399C8.53541 15.6688 9.94082 15.5304 11.218 15.0013C12.4953 14.4723 13.5869 13.5764 14.355 12.4269C15.1231 11.2775 15.5331 9.92607 15.5331 8.54359C15.531 6.69038 14.7939 4.91368 13.4835 3.60324C12.1731 2.2928 10.3965 1.55565 8.54327 1.55349Z"
        :fill="iconColor"
      />
      <path
        d="M17.8639 18.641C17.7618 18.6411 17.6607 18.6211 17.5664 18.5821C17.472 18.543 17.3864 18.4857 17.3143 18.4135L13.5906 14.6894C13.5185 14.6172 13.4613 14.5315 13.4223 14.4372C13.3833 14.343 13.3632 14.2419 13.3633 14.1399C13.3633 14.0379 13.3835 13.9368 13.4226 13.8426C13.4616 13.7483 13.5189 13.6627 13.5911 13.5906C13.6633 13.5185 13.7489 13.4613 13.8432 13.4223C13.9375 13.3833 14.0385 13.3632 14.1406 13.3633C14.2426 13.3633 14.3436 13.3835 14.4379 13.4226C14.5321 13.4616 14.6177 13.5189 14.6899 13.5911L18.4129 17.3153C18.5215 17.424 18.5955 17.5624 18.6254 17.7131C18.6553 17.8638 18.6399 18.02 18.5811 18.1619C18.5222 18.3038 18.4226 18.4251 18.2949 18.5104C18.1671 18.5957 18.0169 18.6412 17.8632 18.6411L17.8639 18.641Z"
        :fill="iconColor"
      />
      <path
        d="M12.1232 10.4544C11.9599 9.98803 11.5789 9.75201 11.2113 9.5235C10.8917 9.32513 10.5894 9.13692 10.3917 8.7998C9.84702 7.86491 9.54789 7.30078 8.65582 7.30078C7.76375 7.30078 7.46362 7.86491 6.91765 8.79897C6.71962 9.13725 6.41616 9.32579 6.09604 9.52466C5.72862 9.75368 5.34837 9.98903 5.18448 10.4544C5.12069 10.6221 5.08861 10.8003 5.08988 10.9797C5.08988 11.7364 5.69231 12.3518 6.35986 12.3518C7.02741 12.3518 7.90666 12.015 8.65832 12.015C9.40998 12.015 10.2764 12.3518 10.9521 12.3518C11.6278 12.3518 12.2201 11.7356 12.2201 10.9797C12.2206 10.8001 12.1879 10.622 12.1235 10.4544H12.1232Z"
        :fill="iconColor"
      />
      <path
        d="M5.23511 9.07967C5.8094 9.07967 6.27492 8.48123 6.27492 7.74271C6.27492 7.00419 5.8094 6.40625 5.23511 6.40625C4.66083 6.40625 4.19531 7.00453 4.19531 7.74288C4.19531 8.48123 4.66067 9.07967 5.23511 9.07967Z"
        :fill="iconColor"
      />
      <path
        d="M7.32105 7.29892C7.8955 7.29892 8.36086 6.70031 8.36086 5.96196C8.36086 5.22361 7.89533 4.625 7.32105 4.625C6.74677 4.625 6.28125 5.22361 6.28125 5.96263C6.28125 6.70164 6.7466 7.29892 7.32105 7.29892Z"
        :fill="iconColor"
      />
      <path
        d="M9.98902 7.29892C10.5635 7.29892 11.0288 6.70031 11.0288 5.96196C11.0288 5.22361 10.5635 4.625 9.98902 4.625C9.41457 4.625 8.94922 5.22361 8.94922 5.96263C8.94922 6.70164 9.41474 7.29892 9.98902 7.29892Z"
        :fill="iconColor"
      />
      <path
        d="M12.0711 9.07967C12.6453 9.07967 13.1109 8.48123 13.1109 7.74271C13.1109 7.00419 12.6453 6.40625 12.0711 6.40625C11.4968 6.40625 11.0312 7.00486 11.0312 7.74321C11.0312 8.48156 11.4966 9.07967 12.0711 9.07967Z"
        :fill="iconColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_32456_2812">
        <path
          d="M0 4.76712C0 2.13431 2.13431 0 4.76712 0H13.8729C16.5057 0 18.64 2.13431 18.64 4.76712V13.8729C18.64 16.5057 16.5057 18.64 13.8729 18.64H4.76712C2.13431 18.64 0 16.5057 0 13.8729V4.76712Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 33,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "#525252",
    },
  },
};
</script>