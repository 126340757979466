<template>
  <!-- :for="value" -->
  <div :class="this.classAdd">
    <label class="text-sm font-proximaMedium" :for="value">{{ label }}</label>
    <input
      :type="type"
      :id="value"
      :placeholder="!editable ? '-' : label"
      :value="value"
      class="w-full rounded-sm px-3 py-3 my-1 text-sm placeholder-darkGrey"
      :class="{ 'focus:outline-none bg-greyishWhite': !editable }"
      :readonly="!editable"
      @input="$emit('update', $event.target.value)"
    />

    <img
      class="absolute right-2 top-9"
      rel="prefetch"
      src="@/assets/images/edit-icon.png"
      alt="edit-icon"
      v-if="editable"
    />

    <FormError :errors="error" />
  </div>
</template>
<script>
import FormError from "../warning/FormError.vue";
export default {
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "text",
    },
    value: String,
    label: String,
    classAdd: String,
    error: String,
  },
  components: {
    FormError,
  },
  computed: {
    classNames() {
      let classNames = [];
      classNames.push(`${this.classAdd}`);
      return classNames;
    },
  },

  model: {
    prop: "value",
    event: "update",
  },
};
</script>
