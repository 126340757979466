import http from "./httpService";
import axios from "axios";
import { apiUrl } from "../config.json";

export function deleteShelter(id) {
  return http.delete(apiUrl + "shelterOrganizations/shelterOrganization/" + id);
}

export function saveShelter(
  provider,
  profileImage,
  data,
  emailAddress,
  phoneNo,
  news_updates
) {
  if (profileImage) {
    const formData = new FormData();
    formData.append("profileImage", profileImage);
    formData.append("provider", provider);
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("streetAddress", data.streetAddress);
    formData.append("country", data.country);
    formData.append("state", data.state);
    formData.append("city", data.city);
    formData.append("postalCode", data.postalCode);
    formData.append("emailAddress", emailAddress);
    formData.append("phoneNo", phoneNo);
    formData.append("website", data.website);
    formData.append("animalShelter", data.animalShelter);
    formData.append("organizationName", organizationName);
    formData.append("taxID", data.taxID);
    formData.append("news_updates", news_updates);
    return axios.post(
      apiUrl + "shelterOrganizations/shelterOrganization/",
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  } else {
    return axios.post(apiUrl + "shelterOrganizations/shelterOrganization/", {
      provider: provider,
      firstName: data.firstName,
      lastName: data.lastName,
      streetAddress: data.streetAddress,
      country: data.country,
      state: data.state,
      city: data.city,
      postalCode: data.postalCode,
      profileImage: null,
      emailAddress: emailAddress,
      phoneNo: phoneNo,
      website: data.website,
      animalShelter: data.animalShelter,
      organizationName: data.organizationName,
      taxID: data.taxID,
      news_updates: news_updates,
    });
  }
}
export function updateShelter(profileImage, form) {
  console.log(animalShelter);
  if (profileImage) {
    const formData = new FormData();
    formData.append("profileImage", profileImage);
    formData.append("firstName", form.firstName);
    formData.append("lastName", form.firstName);
    formData.append("streetAddress", form.streetAddress);
    formData.append("country", form.country);
    formData.append("state", form.state);
    formData.append("city", form.city);
    formData.append("postalCode", form.postalCode);
    formData.append("emailAddress", form.emailAddress);
    formData.append("phoneNo", form.phoneNo);
    formData.append("website", form.website);
    formData.append("animalShelter", form.animalShelter);
    formData.append("organizationName", form.organizationName);
    formData.append("taxID", form.taxID);
    return axios.put(
      apiUrl + "shelterOrganizations/shelterOrganization/" + form.emailAddress,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  } else {
    return axios.put(
      apiUrl + "shelterOrganizations/shelterOrganization/" + form.emailAddress,
      {
        profileImage: null,
        firstName: form.firstName,
        lastName: form.lastName,
        streetAddress: form.streetAddress,
        country: form.country,
        state: form.state,
        city: form.city,
        postalCode: form.postalCode,
        emailAddress: form.emailAddress,
        phoneNo: form.phoneNo,
        website: form.website,
        animalShelter: form.animalShelter,
        organizationName: form.organizationName,
        taxID: form.taxID,
      }
    );
  }
}
