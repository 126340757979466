<template>
  <div>
    <select
      name="pets"
      id="pets"
      class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
      required
      @change="onSelectChange"
      v-model="selectedVal"
      :disabled="editable"
    >
      <option value="" disabled selected>{{ title }}</option>
      <option v-for="data in dataList" :key="data.id" v-bind:value="data.id">
        {{ data.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Select Options",
    },
    dataList: {
      type: [String, Array],
      default: function () {
        return ["Option 1", "Option 2"];
      },
    },
    selectedVal: Number,
  },
  data() {
    return {
      value: "",
    };
  },
  methods: {
    onSelectChange() {
      // console.log(this.value)
      this.$emit("blurred", this.selectedVal);
    },
  },
};
</script>

<style scoped>
select:required:invalid {
  color: #9ca3af;
}
option[value=""][disabled] {
  display: none;
}
</style>
