<template>
  <div
    class="image-input"
    :style="{ 'background-image': `url(${imageD})` }"
    @click="chooseImage"
  >
    <div
      v-if="!imageD"
      class="placeholder rounded-md flex flex-col items-center justify-center transition-all font-proximaLight text-primary"
    >
      <img src="@/assets/images/galery-icon.png" alt="" class="h-14 mb-6" />
      <span>Choose an Image</span>
    </div>
    <input
      class="file-input"
      ref="fileInput"
      type="file"
      @input="onSelectFile"
    />
  </div>
</template>

<script>
export default {
  props: ["imageData"],

  data() {
    return {
      imageD: this.imageData,
    };
  },

  methods: {
    chooseImage() {
      this.$refs.fileInput.click();
    },

    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageD = e.target.result;
          this.$emit("input", files[0]);
        };
        reader.readAsDataURL(files[0]);
      }
    },
  },
};
</script>

<style scoped>
.image-input {
  display: block;
  width: 180px;
  height: 180px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}
.placeholder {
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.placeholder:hover {
  background: #fafafa;
}
.file-input {
  display: none;
}
</style>
