<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4931 7.25671C9.88187 7.25671 8.30685 7.72893 6.96718 8.61364C5.62752 9.49835 4.58337 10.7558 3.96679 12.227C3.35021 13.6983 3.18888 15.3171 3.50321 16.879C3.81754 18.4408 4.59341 19.8755 5.73271 21.0015C6.872 22.1275 8.32355 22.8943 9.90379 23.205C11.484 23.5157 13.122 23.3562 14.6106 22.7468C16.0991 22.1374 17.3714 21.1054 18.2666 19.7814C19.1617 18.4573 19.6395 16.9006 19.6395 15.3082C19.6395 14.2509 19.4288 13.2039 19.0194 12.227C18.61 11.2502 18.0099 10.3626 17.2534 9.61494C16.497 8.86729 15.5989 8.27422 14.6106 7.8696C13.6222 7.46497 12.5629 7.25671 11.4931 7.25671ZM11.4931 21.7494C10.2041 21.7494 8.94409 21.3716 7.87236 20.6639C6.80063 19.9561 5.96531 18.9501 5.47205 17.7732C4.97878 16.5962 4.84972 15.3011 5.10119 14.0516C5.35265 12.8021 5.97335 11.6544 6.88478 10.7536C7.79621 9.85278 8.95745 9.23931 10.2217 8.99078C11.4858 8.74224 12.7962 8.8698 13.9871 9.35732C15.1779 9.84484 16.1957 10.6704 16.9119 11.7297C17.628 12.7889 18.0102 14.0343 18.0102 15.3082C18.0102 17.0165 17.3236 18.6549 16.1014 19.8628C14.8792 21.0708 13.2215 21.7494 11.4931 21.7494Z"
      :fill="iconColor"
    />
    <path
      d="M11.4946 10.4769C11.2785 10.4769 11.0713 10.5618 10.9185 10.7128C10.7658 10.8637 10.6799 11.0685 10.6799 11.2821V14.9777L8.47225 17.1516C8.38697 17.2238 8.31771 17.3126 8.26882 17.4125C8.21992 17.5124 8.19244 17.6212 8.18811 17.7321C8.18377 17.843 8.20268 17.9536 8.24363 18.0569C8.28459 18.1602 8.34671 18.254 8.4261 18.3325C8.50549 18.411 8.60044 18.4724 8.70498 18.5128C8.80951 18.5533 8.92139 18.572 9.03358 18.5677C9.14577 18.5634 9.25586 18.5363 9.35693 18.488C9.45799 18.4396 9.54786 18.3712 9.62089 18.2869L12.0648 15.8714C12.2183 15.7228 12.3061 15.5202 12.3092 15.3078V11.2821C12.3092 11.0685 12.2234 10.8637 12.0706 10.7128C11.9178 10.5618 11.7106 10.4769 11.4946 10.4769Z"
      :fill="iconColor"
    />
    <path
      d="M7.64611 5.75888C7.64611 5.82532 7.62556 5.88904 7.58899 5.93602C7.55242 5.98299 7.50281 6.00939 7.45109 6.00939H1.68259L2.90728 7.58757C2.92963 7.60911 2.94812 7.63656 2.96149 7.66807C2.97487 7.69958 2.98281 7.73442 2.98479 7.77022C2.98677 7.80602 2.98274 7.84195 2.97297 7.87557C2.9632 7.9092 2.94792 7.93973 2.92816 7.96511C2.90841 7.99049 2.88464 8.01012 2.85846 8.02267C2.83228 8.03522 2.80431 8.0404 2.77645 8.03785C2.74858 8.03531 2.72146 8.0251 2.69693 8.00792C2.67239 7.99075 2.65102 7.96699 2.63426 7.93828L1.07415 5.93423C1.04863 5.91598 1.02818 5.88796 1.01565 5.85407C1.00533 5.82409 1 5.79166 1 5.75888C1 5.7261 1.00533 5.69367 1.01565 5.66369C1.02396 5.63242 1.0388 5.60469 1.05855 5.58353C1.07673 5.54731 1.1044 5.52065 1.13655 5.50837C1.16092 5.50092 1.1863 5.50092 1.21066 5.50837H7.45109C7.50281 5.50837 7.55242 5.53477 7.58899 5.58175C7.62556 5.62872 7.64611 5.69244 7.64611 5.75888ZM8.81619 3.25382C8.82199 3.28511 8.82199 3.31772 8.81619 3.34901C8.79539 3.40934 8.75785 3.45757 8.71088 3.48429C8.68652 3.49174 8.66114 3.49174 8.63678 3.48429H2.38074C2.32902 3.48429 2.27942 3.45789 2.24285 3.41091C2.20627 3.36394 2.18573 3.30022 2.18573 3.23378C2.18573 3.16734 2.20627 3.10362 2.24285 3.05665C2.27942 3.00967 2.32902 2.98327 2.38074 2.98327H8.14924L6.92456 1.42513C6.90633 1.40218 6.89183 1.37483 6.88188 1.34464C6.87194 1.31445 6.86675 1.28203 6.86663 1.24925C6.8665 1.21648 6.87143 1.18399 6.88113 1.15367C6.89084 1.12336 6.90513 1.09582 6.92317 1.07264C6.94121 1.04946 6.96266 1.03111 6.98626 1.01864C7.00985 1.00617 7.03514 0.999838 7.06066 1C7.08618 1.00017 7.11142 1.00683 7.13492 1.0196C7.15842 1.03238 7.17971 1.05101 7.19758 1.07442L8.75768 3.07847C8.77743 3.09963 8.79227 3.12736 8.80059 3.15863C8.81164 3.1883 8.817 3.22094 8.81619 3.25382Z"
      :fill="iconColor"
      :stroke="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 33,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "#525252",
    },
  },
};
</script>
