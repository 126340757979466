<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.97974 6.96367C9.83727 6.96367 9.6948 6.96367 9.55253 6.98142C8.85909 7.0347 8.27169 7.3913 7.89841 7.98038C7.52512 8.60498 7.45349 9.35369 7.72052 10.0136C7.77364 10.1565 7.89841 10.2628 8.04068 10.3169C8.21857 10.3702 8.39625 10.3524 8.55643 10.2636C9.42776 9.76417 10.5122 9.76417 11.3843 10.2459C11.5445 10.3355 11.7399 10.3532 11.9001 10.2992C12.0426 10.2459 12.1673 10.1385 12.2202 9.99585C12.4875 9.35349 12.4156 8.60478 12.0601 7.99794C11.7045 7.40987 11.0994 7.05226 10.4237 6.99898C10.2822 6.96346 10.1397 6.96346 9.97954 6.96346L9.97974 6.96367ZM8.2725 10.6915C8.16544 10.6915 8.05939 10.6737 7.95234 10.6382C7.70382 10.5486 7.50742 10.3702 7.41887 10.1387C7.09872 9.37226 7.18806 8.49762 7.61427 7.78422C8.04148 7.10655 8.73513 6.67891 9.53462 6.60707C9.83707 6.58931 10.1574 6.58931 10.4776 6.60707C11.2781 6.67811 11.9717 7.10655 12.3802 7.78422C12.8074 8.47965 12.8783 9.35349 12.5756 10.1032C12.4863 10.3347 12.2907 10.5131 12.0422 10.6027C11.7749 10.709 11.491 10.6913 11.2417 10.5486C10.4774 10.1202 9.53442 10.1387 8.75163 10.5664C8.61016 10.655 8.43227 10.6915 8.2723 10.6915H8.2725Z"
      :fill="iconColor"
      :stroke="iconColor"
      stroke-width="0.5"
    />
    <path
      d="M6.93904 5.69334C6.70823 5.69334 6.51183 5.8893 6.51183 6.12178C6.51183 6.35427 6.70722 6.55022 6.93904 6.55022C7.17086 6.55022 7.36626 6.35427 7.36626 6.12178C7.36626 5.8893 7.16985 5.69334 6.93904 5.69334ZM6.93904 6.90601C6.51183 6.90601 6.15625 6.54941 6.15625 6.12097C6.15625 5.69253 6.51183 5.33594 6.93904 5.33594C7.36626 5.33594 7.72183 5.69253 7.72183 6.12097C7.72183 6.54941 7.36626 6.90601 6.93904 6.90601Z"
      :fill="iconColor"
      :stroke="iconColor"
      stroke-width="0.5"
    />
    <path
      d="M8.85837 4.4261C8.7159 4.4261 8.59113 4.49714 8.50279 4.62206C8.37803 4.81801 8.43196 5.08601 8.62755 5.21013C8.7169 5.28116 8.84066 5.29973 8.94771 5.28116C9.05477 5.2634 9.16082 5.19156 9.21495 5.10276C9.28578 5.01316 9.30429 4.88905 9.28578 4.78169C9.26807 4.67432 9.19643 4.56797 9.10789 4.51368C9.01855 4.44366 8.94771 4.4259 8.85837 4.4259V4.4261ZM8.85837 5.63877C8.69819 5.63877 8.55592 5.58549 8.43115 5.51365C8.07558 5.28217 7.98623 4.7827 8.21805 4.4261C8.3251 4.2477 8.50299 4.14034 8.7161 4.08726C8.9292 4.05174 9.1248 4.08726 9.30248 4.21238C9.65806 4.44386 9.74741 4.94334 9.51559 5.29993C9.39082 5.47833 9.23064 5.58569 9.01754 5.63877C8.96542 5.62101 8.9123 5.63877 8.85837 5.63877Z"
      :fill="iconColor"
      :stroke="iconColor"
      stroke-width="0.5"
    />
    <path
      d="M11.1578 4.44431C11.1047 4.44431 11.0507 4.46207 10.9976 4.47982C10.8906 4.51534 10.8022 4.60495 10.7668 4.7113C10.7314 4.81866 10.7314 4.92501 10.7668 5.03238C10.8022 5.13974 10.8916 5.22833 10.9976 5.26385C11.1047 5.29937 11.2107 5.31713 11.3178 5.26385C11.4248 5.22833 11.5132 5.13873 11.5486 5.03238C11.584 4.92501 11.584 4.81866 11.5486 4.7113C11.5132 4.60394 11.4238 4.51534 11.3178 4.47982C11.2638 4.46207 11.2107 4.44431 11.1576 4.44431H11.1578ZM11.1578 5.65698C11.0507 5.65698 10.9624 5.63922 10.8553 5.6037C10.6599 5.51409 10.5175 5.37222 10.4281 5.17526C10.3573 4.9793 10.3573 4.76538 10.4281 4.58719C10.5175 4.39123 10.6589 4.24835 10.8553 4.15875C11.0507 4.08771 11.264 4.08771 11.4417 4.15875C11.6371 4.24835 11.7796 4.39022 11.8689 4.58719C11.9398 4.78314 11.9398 4.99706 11.8689 5.17526C11.7796 5.37121 11.6381 5.51409 11.4417 5.6037C11.3534 5.63922 11.2638 5.65698 11.1578 5.65698Z"
      :fill="iconColor"
      :stroke="iconColor"
      stroke-width="0.5"
    />
    <path
      d="M13.0595 5.74641C12.8642 5.74641 12.6863 5.88929 12.6323 6.08525C12.6146 6.19261 12.6323 6.31673 12.6855 6.40633C12.7563 6.49593 12.8456 6.56697 12.9527 6.58473C13.0597 6.60249 13.1835 6.58473 13.2728 6.53145C13.3622 6.46041 13.433 6.37081 13.4507 6.26345C13.5039 6.03197 13.3437 5.79949 13.1129 5.76397C13.1129 5.74621 13.0952 5.74621 13.0597 5.74621L13.0595 5.74641ZM13.0595 6.95908C13.0064 6.95908 12.9525 6.95908 12.8994 6.94132C12.704 6.90581 12.5261 6.78068 12.4013 6.60249C12.2943 6.42409 12.2411 6.22813 12.2943 6.01442C12.3836 5.58597 12.7923 5.31898 13.2187 5.40758C13.4141 5.44309 13.592 5.56822 13.7168 5.74641C13.8238 5.92481 13.8769 6.12077 13.8238 6.33448C13.7884 6.53044 13.6636 6.70884 13.486 6.83396C13.3622 6.905 13.202 6.95908 13.0595 6.95908Z"
      :fill="iconColor"
      :stroke="iconColor"
      stroke-width="0.5"
    />
    <path
      d="M14.6984 12.3633H5.30859V12.7199H14.6984V12.3633Z"
      :fill="iconColor"
      :stroke="iconColor"
      stroke-width="0.5"
    />
    <path
      d="M14.6984 14.1484H5.30859V14.505H14.6984V14.1484Z"
      :fill="iconColor"
      :stroke="iconColor"
      stroke-width="0.5"
    />
    <path
      d="M14.6984 15.9336H5.30859V16.2902H14.6984V15.9336Z"
      :fill="iconColor"
      :stroke="iconColor"
      stroke-width="0.5"
    />
    <path
      d="M1.83604 23.1701H18.1796V21.1191C18.1796 20.6374 17.7886 20.2453 17.3083 20.2453H2.70717C2.22684 20.2453 1.83584 20.6374 1.83584 21.1191V23.1701H1.83604ZM18.3398 23.5267H1.65836C1.56901 23.5267 1.48047 23.4557 1.48047 23.3483V21.1367C1.48047 20.459 2.03164 19.9062 2.70738 19.9062H17.2906C17.9663 19.9062 18.5175 20.459 18.5175 21.1367V23.3483C18.5175 23.4547 18.4467 23.5267 18.3396 23.5267H18.3398Z"
      :fill="iconColor"
      :stroke="iconColor"
      stroke-width="0.5"
    />
    <path
      d="M3.02799 19.9066H16.9709L16.3481 18.6584L3.6508 18.6574L3.02799 19.9066ZM17.2556 20.2632H2.74325C2.69013 20.2632 2.61849 20.2277 2.60078 20.1736C2.56536 20.1203 2.56536 20.0484 2.60078 19.9952L3.40128 18.3904C3.4367 18.3371 3.49063 18.3008 3.56146 18.3008H16.473C16.5439 18.3008 16.5978 18.3363 16.6332 18.3904L17.4337 19.9952C17.4691 20.0484 17.4514 20.1203 17.4337 20.1736C17.3798 20.2268 17.309 20.2632 17.2558 20.2632H17.2556Z"
      :fill="iconColor"
      :stroke="iconColor"
      stroke-width="0.5"
    />
    <path
      d="M3.72276 18.302H16.2963V7.38681C16.2963 3.90923 13.4859 1.09077 10.0183 1.09077C6.55067 1.09077 3.72276 3.90944 3.72276 7.38681V18.302ZM16.4565 18.6586H3.54508C3.45573 18.6586 3.36719 18.5876 3.36719 18.4802V7.38701C3.36719 3.71348 6.33676 0.734375 10.0008 0.734375C13.6648 0.734375 16.6344 3.71247 16.6344 7.38701V18.4804C16.6344 18.57 16.5635 18.6588 16.4565 18.6588V18.6586Z"
      :fill="iconColor"
      :stroke="iconColor"
      stroke-width="0.5"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 33,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "#525252",
    },
  },
};
</script>