<template>
  <div class="my-6">
    <OtherSection :pet="this.$route.params.pet" />
    <NavMenuSecondary page="newpet" :data="this.$route.params.pet" />
  </div>
</template>

<script>
import OtherSection from "../components/petrecord/other/OtherSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue";

export default {
  props: { pet: {} },
  components: {
    OtherSection,
    NavMenuSecondary,
  },
};
</script>